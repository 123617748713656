.login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  .form-content {
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .input-textfield {
      margin-top: 7.5px !important;
      width: 100%;
      font-size: 16px;
      margin-bottom: 7.5px !important;
      button {
        margin: 0 !important;
      }
      .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
        padding-right: 0px !important;
      }
    }

    button {
      margin-top: 25px;
      font-weight: bolder;
    }
  }
}
